<template>
  <div class="finance-money">
    <el-card shadow="hover" class="x-card-title" style="margin-bottom: 16px"
      :body-style="{ paddingTop: '12px', paddingBottom: '8px' }">
      <div slot="header" class="row no-gutters">
        <div class="col">
          质保金余额
        </div>
        <div class="col-auto">
          <el-button @click="addMoney" size="small" type="primary">
            缴纳质保金
          </el-button>
        </div>
      </div>

      <div style="color: rgb(227, 64, 91)">
        <span style="font-size: 16px">￥</span>
        <span style="font-size: 32px">{{ detail.retention_money || 0 }}</span>
      </div>
    </el-card>

    <en-table-layout :tableData="pageData.data" :redundancy-height="143" @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <div class="col-auto toolbar-title">
          质保金明细
        </div>
        <el-form-item>
          <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp" style="width: 220px;"
            v-model="daterangeDate" type="daterange" @change="daterangeDateChange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <div class="col-auto">
          <el-button @click="GET_AccountList" size="small" type="primary">搜索</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="流水号" width="80" align="center">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + ((pageData.page_no - 1) * pageData.page_size) }}
          </template>
        </el-table-column>
        <el-table-column label="金额" width="240">
          <template slot-scope="scope">
            {{ scope.row.change_type === 2 ? '-' : '+' }}{{ scope.row.price.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="日期" width="240">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            {{ scope.row.reason }}-{{ scope.row.reamrk }}
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total">
      </el-pagination>
    </en-table-layout>

    <!--缴纳质保金-->
    <el-dialog title="缴纳质保金" :visible.sync="isShowDisRebate">
      <el-form :model="disRebateData" label-width="100px" :rules="disRules" ref="disRebateData" status-icon>
        <el-form-item class="w200" v-model="disRebateData.price" label="支付金额">￥ {{MixinIsEownSupplier?6000:10000}} 元</el-form-item>
        <el-form-item label="支付方式" prop="img">
          <el-radio-group v-model="disRebateData.payment_plugin">
            <el-radio v-if="!MixinIsEownSupplier" :label="1">
              <img src="../../assets/images/wx.jpg" style="width:100px;height:50px;" />
            </el-radio>
            <el-radio :label="3">
              <img src="../../assets/images/underlinepay.png" style="width:100px;height:50px;" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="凭证图片" prop="pay_img" v-if="disRebateData.payment_plugin === 3">
          <el-upload class="site-logo" list-type="picture-card" :action="MixinUploadApi" :show-file-list="false"
            :on-success="(res) => { disRebateData.pay_img = res.url }" :multiple="false">
            <img v-if="disRebateData.pay_img" :src="disRebateData.pay_img" class="site-logo-img" />
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="lijiApply('disRebateData')">立即支付</el-button>
        </el-form-item>
      </el-form>
      <div v-show="showPayBox && disRebateData.payment_plugin === 1" class="cashier-pay-box">
        <div class="pay-item">
          <div class="pay-left" v-if="payment_plugin_id === 'accountPayPlugin'">
            <p>使用账户余额支付</p>
            <div class="pc-pay-img"></div>
          </div>
          <div class="pay-left" v-else>
            <p v-if="payment_plugin_id !== 'weixinPayPlugin'">使用电脑支付</p>
            <div v-if="payment_plugin_id === 'weixinPayPlugin'" class="pc-pay-img">
              <img src="../../assets/images/background-wechat.jpg" />
            </div>
            <div v-else class="pc-pay-img"></div>
            <!-- 	<a class="pay-btn" href="javascript:;" @click="initiatePay(false, 'normal')">立即支付</a> -->
            <i v-if="payment_plugin_id === 'alipayDirectPlugin'" class="icon-or"></i>
          </div>
          <div v-if="payment_plugin_id === 'alipayDirectPlugin' || payment_plugin_id === 'weixinPayPlugin'"
            class="pay-right">
            <p v-if="payment_plugin_id === 'alipayDirectPlugin'">使用支付宝钱包扫一扫即可付款</p>
            <p v-if="payment_plugin_id === 'weixinPayPlugin'">使用微信钱包扫一扫即可付款</p>
            <div class="pay-qrcode" id="pay-qrcode">
              <iframe id="iframe-qrcode" width="200px" height="200px" scrolling="no" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import * as API_account from '@/api/account'
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
// import * as API_logistics from '@/api/expressCompany'
// import {handleDownload} from '@/utils'
// import {Foundation} from '@/../ui-utils'

export default {
  name: 'goodsList',
  components: { EnTableLayout },
  data () {
    const checkRadio = (rule, value, callback) => {
      console.log(rule, value, callback);
      if (value < 1) {
        return callback(new Error('请选择支付方式'));
      } else {
        callback();
      }
    };

    return {
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      txInfo: {},
      txRules: {
        a4a4: [
          {
            required: true,
            message: '请输入礼包名称',
            trigger: 'blur'
          }
        ],
      },
      list1: [
        {
          value: 1,
          label: '选项1'
        }
      ],
      detail: {
        retention_money: 0
      },
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        start_time: '',
        end_time: '',
      },

      daterangeDate: '',

      /** 列表分页数据 */
      pageData: { data: [] },

      /** 快递列表 */
      logiList: [],

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      showPayBox: false,
      payment_plugin_id: 'weixinPayPlugin',
      isShowDisRebate: false,
      gateway_url: '',
      /** 充值数据 */
      disRebateData: {
        shop_name: '',
        /** 充值价格 */
        price: '',

        /** 备注 */
        marks: '',
        /** 支付方式 */
        payment_plugin: 0,
        /** 服务类型  */
        service_type: 5,
        /**线下支付图片*/
        pay_img: ''
      },
      /** 分销返利校验规则 */
      disRules: {
        payment_plugin: [
          {
            required: true,
            message: '支付方式不能为空',
            trigger: 'blur'
          },
          {
            validator: checkRadio,
            trigger: 'blur'
          }
        ],
        pay_img: [
          {
            required: true,
            message: '图片不能为空',
            trigger: 'blur'
          },
        ]
      },
    }
  },
  activated () {
    this.GET_AccountList()
    this.getAcountMoney()
  },
  mounted () {
    this.GET_AccountList()
    this.getAcountMoney()
  },
  methods: {
    daterangeDateChange (val) {
      this.params.start_time = val ? val[0] / 1000 : ''
      this.params.end_time = val ? val[1] / 1000 : ''
    },
    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess (response, file, fileList) {

      // this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString()
      // this.$refs['baseInfoForm'].validateField('goods_gallery')
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove (file, fileList) {
      // this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
      // 	if (key.img_id !== -1) {
      // 		if (key.img_id === file.img_id) {
      // 			this.baseInfoForm.goods_gallery_list.splice(index, 1)
      // 		}
      // 	} else {
      // 		if (key.name === file.response.name) {
      // 			this.baseInfoForm.goods_gallery_list.splice(index, 1)
      // 		}
      // 	}
      // })
      // if (fileList.length <= 0) {
      // 	this.baseInfoForm.goods_gallery_list = []
      // 	this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString()
      // }
      // this.$refs.baseInfoForm.validateField('goods_gallery')
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size
      this.GET_AccountList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page
      this.GET_AccountList()
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data
      }
      this.GET_AccountList()
    },

    GET_AccountList () {
      this.loading = true
      API_account.retentionMoneyList(this.params).then(response => {
        this.loading = false
        this.pageData = response;
      })
    },

    handleSelectionChange (val) {
      let ids = []
      val.forEach(item => {
        ids.push(item.order_id)
      })
      this.idsList = ids
    },
    handleStockGoods (row) {
      this.$router.push('/order/detail/' + row.sn)
    },
    getAcountMoney () {
      API_account.getShopBalance().then(response => {
        console.log(response, 'response');
        this.detail = response;
      });
    },
    // 充值
    addMoney () {
      this.disRebateData.price = this.MixinIsEownSupplier?6000:10000;
      this.disRebateData.payment_plugin = 0;
      this.disRebateData.service_type = 4;
      this.disRebateData.pay_img = '';
      this.disRebateData.marks = '';
      this.isShowDisRebate = true;
      this.showPayBox = false;
    },
    lijiApply (formName) {
      if (this.disRebateData.payment_plugin === 0) {
        this.$message.error('请选择支付方式');
        return;
      }
      if (
        this.disRebateData.payment_plugin === 3 &&
        this.disRebateData.pay_img === ''
      ) {
        this.$message.error('请上传凭证图片');
        return;
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          var obj = {};
          obj.price = this.disRebateData.price;
          obj.service_type = this.disRebateData.service_type;
          obj.shop_id = this.disRebateData.shop_id;
          obj.marks = this.disRebateData.marks;
          // 创建服务订单
          API_account.createOrder(obj).then(response => {
            console.log(
              'this.disRebateData.payment_plugin=' +
              this.disRebateData.payment_plugin
            );
            // this.isShowDisRebate = false;
            // this.$message.success("当前商品分销返利金额修改成功");
            if (this.disRebateData.payment_plugin === 1) {
              // 获取支付的二维码
              this.weixinPayPlugin(response);
            } else if (this.disRebateData.payment_plugin === 2) {
              //余额支付
              this.shopBalancePlugin(response);
            } else {
              //线下支付
              this.payDown(response);
            }
          });
        }
      });
    },
    //微信支付
    weixinPayPlugin (sn) {
      var obj2 = {};
      obj2.payment_plugin_id = 'weixinPayPlugin';
      obj2.client_type = 'PC';
      obj2.pay_mode = 'ar';
      this.showPayBox = true;
      // 支付宝、微信支付
      this.$nextTick(() => {
        document.getElementById(
          'pay-qrcode'
        ).innerHTML = `<iframe id="iframe-qrcode" width="200px" height="200px" scrolling="no" frameborder="0"></iframe>`;
        // 如果是普通支付方式，
        API_account.paymentServiceOrder(sn, obj2).then(response => {
          console.log(response.gateway_url);
          this.gateway_url = response.gateway_url;
          let $formItems = '';
          response.form_items &&
            response.form_items.forEach(item => {
              $formItems += `<input name="${item.item_name}" type="hidden" value='${item.item_value}' />`;
            });
          $formItems += `<input name="path" type="hidden" value='/finance/money' />`;
          let $form = `<form action="${response.gateway_url}" method="post">${$formItems}</form>`;
          const qrIframe = document.getElementById('iframe-qrcode')
            .contentWindow.document;
          qrIframe.getElementsByTagName('body')[0].innerHTML = $form;
          qrIframe.forms[0].submit();
        });
      });
    },
    //余额支付
    shopBalancePlugin (sn) {
      let order_params = {
        payment_plugin_id: 'shopBalancePlugin',
        pay_mode: 'normal',
        client_type: 'PC',
        trade_type: 'service'
      };
      API_account.paymentServiceOrder(sn, order_params).then(res => {
        console.log(res, 'this.order_params');
        let callBackParams = {
          pay_order_no: res.pay_order_no,
          need_pay_money: res.need_pay_money,
          out_trade_no: res.out_trade_no
        };
        API_account.callBack(res.call_back_url, callBackParams).then(resp => {
          this.isShowDisRebate = false;
          this.$message.success('支付成功');
        });
      });
    },
    //线下支付
    payDown (sn) {
      let callBackParams = {
        pay_img: this.disRebateData.pay_img
      };
      API_account.payDown(callBackParams, sn).then(res => {
        this.isShowDisRebate = false;
        this.$message.success('操作成功');
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}

.site-logo-img {
  width: 100%
}

.cashier-pay-box {
  border: 1px solid #e1e1e1;
  background: #f4f4f4;
  margin: 0 0 40px 0;
  padding-top: 3px;

  .pay-item {
    display: flex;
    justify-content: center;
    margin: 0 3px 3px 3px;
    background: #fff;
    overflow: hidden;
    height: 335px;

    .pay-left {
      width: 471px;
      float: left;
      height: 310px;
      position: relative;

      p {
        width: 450px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin: 25px 0 0 0;
        font-size: 16px;
      }

      .pc-pay-img {
        height: 92px;
        margin-left: 150px;
        margin-top: 48px;
        width: 165px;
        background: url(../../assets/images/icons-cashier.png) no-repeat 0 -1417px;
      }

      .pay-btn {
        width: 180px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background: #fff;
        display: block;
        margin: 30px auto 0 auto;
      }

      .icon-or {
        display: block;
        background: url(../../assets/images/icons-cashier.png) no-repeat -212px -1417px;
        height: 41px;
        left: 464px;
        position: absolute;
        top: 130px;
        width: 31px;
      }
    }

    .pay-right {
      float: left;
      border-left: 1px solid #f4f4f4;

      p {
        width: 472px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin: 25px 0 0 0;
        font-size: 16px;
      }

      .pay-qrcode {
        margin: 20px auto;
        height: 200px;
        width: 200px;
        overflow: hidden;
      }
    }
  }
}
</style>
